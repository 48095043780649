<template>
    <!-- Component: organisms/timeline -->
    <div class="container">
        <p class="general-container__title">{{ title }}</p>
        <div class="scroll-wrapper">
            <!-- This is NOT the production timeline. -->
            <iframe onmousewheel="" frameborder="0" scrolling="no" style="border-width:0;" id="tl-timeline-iframe" width="100%" height="480" src="https://www.tiki-toki.com/timeline/embed/1674185/1951906709/"></iframe>
        </div>
    </div>
    <!-- End Component: organisms/timeline -->
</template>

<script>
    export default {
        name: 'Timeline Test',
        props: [
            'title'
        ],
        methods: {
            receiveMessage (event) {
                console.log(event.data)
            }
        },
        mounted () {
            window.addEventListener('message', this.receiveMessage);
            if (window.postMessage) {
                var tlMouseupFunc = function() {
                    var tlFrame = document.getElementById("tl-timeline-iframe");
                    if (tlFrame.contentWindow && tlFrame.contentWindow.postMessage) {
                        tlFrame.contentWindow.postMessage("mouseup","*");
                    }
                }
                if (typeof window.addEventListener != "undefined") {
                    window.addEventListener("mouseup", tlMouseupFunc, false);
                } else if (typeof window.attachEvent != "undefined") {
                    window.attachEvent("onmouseup", tlMouseupFunc);
                }
            }
        },

        beforeDestroy () {
            window.removeEventListener('message', this.receiveMessage);
        },
        setup(props) {
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    iframe {
        border-radius: 10px;
    }
    .general-container__title {
        text-align: center;
    }
    .scroll-wrapper {
    	-webkit-overflow-scrolling: touch;
  	    overflow-y: scroll;
        @media screen and (max-width: 1028px) {
            margin: 0 15px;
        }
    }

</style>

